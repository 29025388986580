import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import InfoAPI from './InfoAPI';
import '../styles/overlay.css';
import { formatTitleToDB, formatTitleToDisplay } from './Functions';
import { refreshTime } from '../utils/DisplaySettings';
import EmotionDisplay from './EmotionDisplay';
import { primaryColor, secondaryColor, tertiaryColor } from '../utils/DisplaySettings';
import logo_small from '../images/logo_small.png'

// Clean styling without any nav elements
const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  color: white;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  padding: 1rem;
`;

const EventInfo = styled.div`
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem;
`;

// Add specific styling for messages
const Message = styled.div`
  color: white;
  font-size: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem;
  text-align: center;
`;

const EventOverlay = () => {
    const { eventId } = useParams();
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [session_name, setSessionName] = useState('');
    const [debugMessage,setDebugMessage] = useState('');
    const [songPlayed,setSongPlayed] = useState('');
    const [artist,setArtist] = useState('');
    const [impressions,setImpressions] = useState([]);
    const [spotOnUser,setSpotOnUser] = useState('');
    const [pointsPerWin,setPointsPerWin] = useState(0);
    const [showSessionImpressions,setShowSessionImpressions] = useState(true);
    const [emotionsCount,setEmotionsCount] = useState([]);
    const [roundDiameter,setRoundDiameter] = useState(200);
    const isMounted = useRef(false);
    const [emojiPositions, setEmojiPositions] = useState({});

    const emotionEmojis = {
        'happy': '😊',
        'surprised': '😲',
        'calm': '😌',
        'disgusted': '🤢',
        'confused': '😕',
        'fear': '😨',
        'angry': '😠',
        'head_nod': '🤗'
    };

    const loadLeaderBoard = async() => {
       
        const urlParams = new URLSearchParams(window.location.search);
        const session_name = urlParams.get('session_name');
        setSessionName(session_name);

        const requestBody = JSON.stringify({
            'session_name': urlParams.get('session_name'),
            'info_type': 'leaderboard'
        });
        
        const jsonData = await InfoAPI(requestBody);
        if (jsonData && jsonData.body) {
            let result;
            try {
                result = typeof jsonData.body === 'string' 
                    ? JSON.parse(jsonData.body)
                    : jsonData.body;
                const newLeaderBoard = result.map(element => ({
                    user: element[0],
                    points: element[1],
                    request: element[2] && element[3] ? `${formatTitleToDisplay(element[3])} by ${formatTitleToDisplay(element[2])}` : ''
                }));
                setLeaderBoard(newLeaderBoard);
            } catch (error) {
                console.error('Error parsing leaderboard data:', error);
            }
        }
    };

    const loadSongPlayed = async() => {
        const urlParams = new URLSearchParams(window.location.search);

        const requestBody = JSON.stringify({
            'session_name': urlParams.get('session_name'),
            'info_type': 'songplayed'
        });
        const jsonData = await InfoAPI(requestBody);
        if (jsonData && jsonData.body) {
            const result = JSON.parse(jsonData.body);
            var song= result.song_name;
            var artist= result.artist_name;
            if (song && artist) {
                song= formatTitleToDisplay(song);
                artist=formatTitleToDisplay(artist);
                setSongPlayed(song);
                setArtist(artist);
            }
        }
    };

    const loadImpressions = async() => {
        const urlParams = new URLSearchParams(window.location.search);

        const requestBody = JSON.stringify({
            'session_name': urlParams.get('session_name'),
            'info_type': 'impressions'
        });
        const jsonData = await InfoAPI(requestBody);

        if (jsonData && jsonData.body) {
            const result = JSON.parse(jsonData.body);
            setPointsPerWin(result.pointsperwin);
            setSpotOnUser(result.spot_on_user);
            setImpressions(result.impressions);
        }
    };

    const loadEmotions = async() => {
        const urlParams = new URLSearchParams(window.location.search);
        
        const requestBody = JSON.stringify({
            'session_name': urlParams.get('session_name'),
            'info_type': 'emotions_count'
        });
        const jsonData = await InfoAPI(requestBody);
        if (!jsonData) return;
        if (jsonData && jsonData.body) {
            const result = JSON.parse(jsonData.body);
            setEmotionsCount(result.emotions);
        }
    };

    const generateEmojiPositions = (emotionsCount) => {
        const positions = {};
        Object.entries(emotionsCount).forEach(([emotionType, count]) => {
            if (emotionType === 'calm') return;
            positions[emotionType] = Array(count).fill(0).map(() => ({
                x: Math.random() * roundDiameter * 0.5,
                y: Math.random() * roundDiameter * 0.5
            }));
        });
        return positions;
    };

    useEffect(() => {
        // Add overlay class to body when component mounts
        document.body.classList.add('overlay');
        
        // Load data immediately and set up interval
        const timer = setInterval(() => {
            loadLeaderBoard();
            loadSongPlayed();
            loadImpressions();
            loadEmotions();
        }, refreshTime);
        
        // Remove overlay class and clear interval when component unmounts
        return () => {
            clearInterval(timer);
            document.body.classList.remove('overlay');
        };
    }, []); // Empty dependency array means this runs once on mount

    useEffect(() => {
        const positionUpdateInterval = setInterval(() => {
            setEmojiPositions(generateEmojiPositions(emotionsCount));
        }, 2000);

        return () => clearInterval(positionUpdateInterval);
    }, [emotionsCount, roundDiameter]);

    return (
        <OverlayContainer>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <img 
                src={logo_small} 
                alt="Logo"
                style={{
                    width: '50%',
                    marginBottom: '20px',
                    maxWidth: '200px'
                }}
            />
            </div>
            <Message>
                Join us on www.crowd-sensor.com
            </Message>
            <div style={{ marginBottom: '20px' }}>
                <span style={{ color: secondaryColor }}>Session:</span> <span style={{ color: 'white' }}>{session_name}</span>
            </div>
            <div style={{ marginBottom: '20px' }}>
                <span style={{ color: secondaryColor }}>Played:</span> <span style={{ color: 'white' }}>{songPlayed} by {artist}</span>
            </div>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px', color: primaryColor }}>
                <div style={{ flex: '1 1 25%' }}>Viewer</div>
                <div style={{ flex: '1 1 25%' }}>Points</div>
                <div style={{ flex: '1 1 25%' }}>Request</div>
                <div style={{ flex: '1 1 25%' }}></div>
            </div>
            <hr />
            {leaderBoard.length > 0 && 
                leaderBoard.map((user, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', color: 'white' }}>
                        <div style={{ flex: '1 1 25%' }}>{user.user}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.points}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.request}</div>
                        <div style={{ flex: '1 1 25%' }}></div>
                    </div>
                ))}
            <div style={{ margin: '50px 0' }}></div>
            <div style={{
                width: `${roundDiameter}px`,
                height: `${roundDiameter}px`,
                backgroundColor: tertiaryColor,
                borderRadius: '50%',
                margin: '0 auto',
                position: 'relative',
                overflow: 'hidden'
            }}>
                
               {Object.entries(emotionsCount).map(([emotionType, count]) => {
                        if (emotionType === 'calm') return null;
                        return (emojiPositions[emotionType] || []).map((position, i) => (
                            <div 
                                key={`${emotionType}-${i}`} 
                                style={{
                                    position: 'absolute',
                                    left: `${position.x}px`,
                                    top: `${position.y}px`, 
                                    fontSize: '50px'
                                }}
                            >
                                {emotionEmojis[emotionType] || '😶'}
                            </div>
                        ));
                })}
            </div>
            <div style={{ margin: '50px 0' }}></div>
            {showSessionImpressions && (
                <>
                    <div style={{ color: primaryColor, fontWeight: 'bold', fontSize: '1.2em',marginBottom: '20px' }}>
                        Impressions from the audience
                    </div>
                    <div style={{ marginBottom: '20px' }}>
                        <span style={{ color: secondaryColor }}>Spot On:</span> <span style={{ color: 'white' }}>{spotOnUser} has obtained {pointsPerWin} points</span>
                    </div>
                    <EmotionDisplay emotions={impressions} color='white' role='creator' />
                </>
            )}
        </OverlayContainer>
    );
};

export default EventOverlay;