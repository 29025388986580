import './App.css';
import { useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import EventOverlay from './components/EventOverlay';

import Home from './components/Home.jsx';
import Callback from './components/Callback.jsx';
import Attend from './components/Attend.jsx';
import Create from './components/Create.jsx';
import Profile from './components/Profile.jsx';


import { AuthProvider } from './components/AuthContext';




const App = () => {



  useEffect(() => {
    },[]);
    return (
      <AuthProvider>
      <Router>
        <Navigation />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/attend" element={<Attend />} />
        <Route path="/create" element={<Create />} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
        <Route path="/overlay/:eventId" element={<EventOverlay />} />
        </Routes>
      </Router>
      </AuthProvider>
    );
  }

export default App;
