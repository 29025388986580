import React from 'react';

// EmotionDisplay is a component that displays the emotions of the user directly on the UI, or as count of emotions for the creator
const EmotionDisplay = ({ emotions, color, role }) => {
    const minFontSize = 12; // Minimum font size
    return (
        <div>
            {role === 'creator' ? (
                Object.entries(emotions)
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([emotionType, confidence], index) => {
                        const fontSize = Math.max(minFontSize, confidence);
                        return (
                            <div key={index} style={{ fontSize: `${fontSize}px`, color: color }}>
                                {emotionType}
                            </div>
                        );
                    })
            ) : (
                <div style={{
                    position: 'relative',
                    width: '320px',
                    height: '320px'
                }}>
                    <div style={{
                        position: 'absolute',
                        width: '300px',
                        height: '300px',
                        borderRadius: '50%',
                        backgroundColor: 'black',
                        border: '2px solid white',
                        left: '10px',
                        top: '10px'
                    }} />
                    {Object.entries(emotions)
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([emotionType, confidence], index, array) => {
                            // Calculate position on a polygon
                            const totalEmotions = array.length;
                            const angle = (2 * Math.PI * index) / totalEmotions;
                            const radius = 100; // Size of the polygon
                            const x = radius * Math.cos(angle);
                            const y = radius * Math.sin(angle);

                            return (
                                <div 
                                    key={index}
                                    style={{
                                        position: 'absolute',
                                        transform: `translate(${x + 115}px, ${y + 150}px)`,
                                        color: confidence > 20 ? '#028cd5' : color,
                                        fontWeight: confidence > 20 ? 'bold' : 'normal'
                                    }}
                                >
                                    {emotionType}
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default EmotionDisplay;