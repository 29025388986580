import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import UserAPI from './UserAPI';
import InfoAPI from './InfoAPI';
import { Title, Text } from './Typography';

const SettingsMenuCreate = () => {
    const { idToken } = useAuth();
    const [timeDelay, setTimeDelay] = useState(null);
    const [timeInterval, setTimeInterval] = useState(null);
    const [embedding, setEmbedding] = useState(null);
    const [embeddingURL, setEmbeddingURL] = useState(null);

    const changeSetting = async (setting) => {
        const fetchedValue = document.getElementById(setting + 'input')?.value || '';
        if (fetchedValue === '') {
            return;
        }
        const requestBody = JSON.stringify({
            'idToken': idToken,
            'request_type': 'changesetting',
            'setting': setting,
            'value': fetchedValue
        });
        await UserAPI(requestBody);
        const jsonData = await UserAPI(JSON.stringify({ 'idToken': idToken, 'request_type': 'getsettings' }));
        const result = JSON.parse(jsonData.body);
        setTimeDelay(result.timedelay);
        setTimeInterval(result.timeinterval);
        setEmbedding(result.embed_bool);
        setEmbeddingURL(result.embed_url);
    };

    const getEmbedding = async () => {
        const bodyData = JSON.stringify({
            'info_type': 'embedding',
            'session_name': localStorage.getItem('selectedSession')
        });
        const jsonData = await InfoAPI(bodyData);
        const result = JSON.parse(jsonData.body);
        console.log('Embedding:', result.embed_bool);
        return result.embed_bool;
    }

    const startstopEmbedding = async () => {
        const embeddingBool = await getEmbedding();
        console.log('Embedding:', embeddingBool);
        console.log('Embedding bool:', embeddingBool);
        if (!embeddingBool) {
            var embedURL = document.getElementById('embedURLinput')?.value || '';
       
            const requestBody = JSON.stringify({
            'idToken': localStorage.getItem('idToken'),
            'request_type': 'startembedding',
            'url': embedURL
          });
          console.log('requestBody:', requestBody);
          await UserAPI(requestBody);
          setEmbedding(true);
          setEmbeddingURL(embedURL);
        }
        else {
            const requestBody = JSON.stringify({
                'idToken': localStorage.getItem('idToken'),
                'request_type': 'stopembedding'
            });
            await UserAPI(requestBody);
            setEmbedding(false);
            setEmbeddingURL(null);
        }
    };

    const fetchEmbeddingURL = async () => {
        var embeddingURL = null;
        const bodyData = JSON.stringify({
            'session_name': localStorage.getItem('selectedSession'),
            'info_type': 'embedding'
        });
        const jsonData = await InfoAPI(bodyData);
        console.log("JSON Data:", jsonData);
        if (jsonData.body){
            const result = JSON.parse(jsonData.body);
            embeddingURL = result.embed_url;
            console.log('Embedding URLas:', embeddingURL);
            setEmbeddingURL(embeddingURL);
            return embeddingURL;
        }
        
    }

    useEffect(() => {
        const getSettings = async () => {
            const jsonData = await UserAPI(JSON.stringify({ 'idToken': idToken, 'request_type': 'getsettings' }));
            const result = JSON.parse(jsonData.body);
            console.log("Result:", result);
            setTimeDelay(result.timedelay);
            setTimeInterval(result.timeinterval);
            console.log("Embedding bool:", result.embed_bool);
            setEmbedding(result.embed_bool);
            console.log("Embedding URL:", result.embed_url);
            setEmbeddingURL(result.embed_url);
        };
        getSettings();
    }, [idToken]);
    
    return (
        <div>
            <div>
                <p>Current Time Delay: {timeDelay} seconds</p>
                <p>Current Time Interval: {timeInterval} seconds</p>
                {embedding && embeddingURL && (
                    <p>Current Embedding URL: {embeddingURL}</p>
                )}
            </div>
            <div>
                <button onClick={() => changeSetting("timedelay")}>Change Time Delay</button>
                <input 
                    id="timedelayinput" 
                    type="text" 
                    placeholder={`Enter (current value: ${timeDelay})`}
                />
            </div>
            <div >
                <button onClick={() => changeSetting("timeinterval")}>Change Time Interval</button>
                <input 
                    id="timeintervalinput" 
                    type="text" 
                    placeholder={`Enter (current value: ${timeInterval})`}
                />
            </div>

            <div ></div>
            <button onClick={startstopEmbedding} id="embeddingButton">
              {embedding ? 'Stop embedding' : 'Start embedding'}
            </button>
            <input 
                    id="embedURLinput" 
                    type="text" 
                    placeholder={`Enter`}
                />
        </div>
    );
};

export default SettingsMenuCreate;
