import EnvironmentVariables from './EnvironmentVariables';

export const fetchTokens = async () => {
    const code = new URL(window.location.href).searchParams.get('code');
    if (code) {
        await fetch(EnvironmentVariables.CognitoURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                grant_type: 'authorization_code',
                client_id: EnvironmentVariables.ClientID,
                code: code,
                redirect_uri: EnvironmentVariables.RedirectURI
            })
        })
        .then(response => response.json())
        .then(async data => {
            const idToken = data.id_token;
            const accessToken = data.access_token;
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('accessToken', accessToken);
        })
    }

}       


export const checkAuthStatus = () => {
    const idToken = localStorage.getItem('idToken');
    return idToken ? true : false;
};
