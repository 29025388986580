import React, { useState, useEffect, useRef } from 'react';
import { fetchTokens } from '../utils/Auth';
import UserAPI from './UserAPI';
import { Title, Text } from './Typography';
import isMobileFunc from '../utils/DisplaySettings';
import logo from '../images/logo.png';
const CallBack = () => {
    const isMounted = useRef(false);
    const [isMobile, setIsMobile] = React.useState(isMobileFunc());

    const createUser = async () => {
        const idToken = localStorage.getItem('idToken');
        const requestBody = JSON.stringify({
            'idToken': idToken,
            'request_type': 'newuser'
        });
        await console.log('API request:', requestBody);
        // Call API for new user creation
        await UserAPI(requestBody);
    }



    useEffect(() => {
            fetchTokens();
            createUser();

    }, []);

    return (
        <div style={{
            marginTop: '5vh', // Reduced from 20vh to move content up
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start', // Changed from center to flex-start
            minHeight: isMobile ? 'calc(100vh - 120px)' : '100vh',
            width: '100%',
            padding: '0 20px',
            boxSizing: 'border-box'
        }}>
            <img 
                src={logo} 
                alt="Logo"
                style={{
                    width: isMobile ? '100%' : '50%',
                    marginBottom: '20px',
                    maxWidth: '600px'
                }}
            />
                <div style={{
                    width: '100%',
                    maxWidth: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <h1 style={{ 
                        fontSize: '2.5rem', 
                        marginBottom: '30px', 
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        Welcome !
                    </h1>
                </div>

        </div>
    );
}

export default CallBack;