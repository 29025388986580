
const PublicAPICall = async (requestBody,url) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': ``
                },
                body: requestBody
            });
            const data = await response.json();
            return data;
        } catch (error) {
            await console.error('Error calling API'+url+':', error);
        }
};

export default PublicAPICall;