const isMobileFunc = () => {
    return window.innerWidth <= 768;
}

const primaryColor = '#fe4462';
const secondaryColor = '#028cd5';
const tertiaryColor = '#8cd2ec';

// scanFrequency is the time between each scan of the user's face
const scanFrequency = 250; // in milliseconds

// refreshTime is the time interval between each data fetch from database and refresh of the UI
const refreshTime = 2000; // in milliseconds


export { refreshTime, scanFrequency, primaryColor, secondaryColor, tertiaryColor };
export default isMobileFunc;