import React from 'react';
import { Link } from 'react-router-dom';
import homeIcon from '../images/homeIcon.png';
import browseIcon from '../images/browseIcon.png';
import createIcon from '../images/createIcon.png';
import profileIcon from '../images/profileIcon.png';
import logo from '../images/logo.png';
import isMobileFunc from '../utils/DisplaySettings';

const buttonHeight = '80px';

function Navigation() {
    const [isMobile, setIsMobile] = React.useState(isMobileFunc());

    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(isMobileFunc());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderLink = (path, label, icon) => (
        <Link 
            to={path} 
            style={{ 
                cursor: 'pointer', 
                transition: 'box-shadow 0.3s', 
                padding: '10px 20px', 
                color: 'white', 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : 'row', // Column layout for mobile
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                margin: '10px',
                width: isMobile ? 'calc(25%)' : '200px', // 25% for 4 buttons, minus margins
                fontSize: '1rem' // Added increased font size
            }}
            onMouseEnter={(e) => !isMobile && (e.currentTarget.style.boxShadow = '0 0 50px rgba(254, 68, 98, 1.0)')}
            onMouseLeave={(e) => !isMobile && (e.currentTarget.style.boxShadow = 'none')}
        >
            <img src={icon} alt={`${label} icon`} style={{ marginBottom: isMobile ? '5px' : '0', marginRight: isMobile ? '0' : '10px', width: '64px', height: '64px' }} />
            <span style={{ fontSize: isMobile ? '1rem' : '2rem' }}>{label}</span>
        </Link>
    );

    return (
        <nav style={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'row' : 'column',
            flexWrap: 'nowrap', 
            alignItems: isMobile ? 'center' : 'flex-start',
            position: isMobile ? 'relative' : 'fixed',
            left: 0,
            top: 0,
            height: isMobile ? 'auto' : '100vh',
            width: isMobile ? '100%' : 'auto',
            backgroundColor: '#000',
            padding: isMobile ? '10px' : '20px 0',
            zIndex: 1000
        }}>
            {!isMobile && <img src={logo} alt="Logo" style={{ 
                height: '120px', // Increased from buttonHeight (80px) to 120px
                marginRight: isMobile ? '10px' : '0',
                marginBottom: isMobile ? '0' : '20px',
                alignSelf: isMobile ? 'center' : 'flex-start'
            }} />}
            {renderLink("/", "Home", homeIcon)}
            {renderLink("/attend", "Attend", browseIcon)}
            {renderLink("/create", "Create", createIcon)}
            {renderLink("/profile", "Profile", profileIcon)}
        </nav>
    );
}

export default Navigation;